<template>
  <div>
    <b-card title="Experience Updates">
      <b-tabs>
        <b-tab active title="English">
          <b-card-text>
            <validation-observer ref="experienceForm">
              <b-form>
                <b-row>
                  <b-col md="6" class="align-self-center">
                    <b-form-checkbox
                      checked="true"
                      v-model="experience.openNewPage"
                      name="check-button"
                      class="mb-1 mt-1"
                      switch
                      inline
                    >
                      Has Inner Page
                    </b-form-checkbox>
                    <b-form-group class="mb-0">
                      <validation-provider
                        #default="{ errors }"
                        name="Experience Title"
                        rules="required"
                      >
                        <b-form-input
                          v-model="experience.title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Experience Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <v-select
                        multiple
                        v-if="!experience.openNewPage"
                        class="mt-2"
                        v-model="experience.hotels"
                        :reduce="
                          (hotel) => {
                            return { hotel: hotel._id };
                          }
                        "
                        label="name"
                        placeholder="Select Hotels to list"
                        :options="hotels"
                      />

                      <v-select
                        class="mt-2"
                        v-model="experience.type"
                        label="Type"
                        placeholder="Experience Type"
                        :options="['Maharashtra', 'Default']"
                      />

                      <b-form-textarea
                        class="mt-2"
                        v-model="experience.description"
                        id="textarea-rows"
                        placeholder="Description"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2" class="align-self-center">
                    <ComponentMediaSelector
                      :isMultiple="false"
                      @mediaSelected="handleMediaSelect"
                    />
                  </b-col>
                  <b-col v-if="experience.featuredImage" md="3">
                    <b-img
                      v-bind="mainProps"
                      rounded
                      :src="experience.featuredImage"
                    />
                    <a
                  @click="experience.featuredImage = ''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Image</a
                >
                  </b-col>
                </b-row>
                <b-row>
                 <b-col md="2">
                  <b-form-group class="mb-0 mt-1" label="Select Icon">
                    <ComponentMediaSelector
                    :isMultiple="false"
                    @mediaSelected="handleIconSelect"
                  />
                  </b-form-group>
                </b-col>
                <b-col v-if="experience.icon" class="mt-1" md="3">
                  <b-img
                    v-bind="mainProps"
                    rounded
                    :src="experience.icon"
                  />
                  <a
                  @click="experience.icon = ''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Icon</a
                >
                </b-col>
              </b-row>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-tab>
        <b-tab title="Hindi">
          <b-card-text>
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <b-form-input
                      v-model="experience.translations.hi.title"
                      placeholder="Experience Title (Hindi)"
                    />

                    <b-form-textarea
                      class="mt-2"
                      v-model="experience.translations.hi.description"
                      id="textarea-rows"
                      placeholder="Description (Hindi)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
        <b-tab title="Marathi">
          <b-card-text>
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <b-form-input
                      v-model="experience.translations.mr.title"
                      placeholder="Experience Title (Marathi)"
                    />

                    <b-form-textarea
                      class="mt-2"
                      v-model="experience.translations.mr.description"
                      id="textarea-rows"
                      placeholder="Description (Marathi)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
      </b-tabs>
      <b-card-text class="mt-2">
        <b-row>
          <b-col>
            <b-button @click="updateExperience" variant="primary"
              >Update</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BImg,
  BFormTextarea,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BImg,
    BFormTextarea,
    BTabs,
    BTab,
    BCardText,
    ComponentMediaSelector,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 175,
        height: 175,
      },
      experienceID: "",
      hotels: [],
      experience: {
        title: "",
        location: "",
        description: "",
        type: "",
        icon: "",
        openNewPage: "",
        hotels: "",
        featuredImage: "",
        translations: {
          hi: {
            title: "",
            location: "",
            description: "",
          },
          mr: {
            title: "",
            location: "",
            description: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        this.experience.featuredImage = mediaObject.media.src;
      }
    },
    updateExperience() {
      this.$refs.experienceForm.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/experiences/update`,
              { id: this.experienceID, updates: this.experience },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been updated successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while updating experience!"
              );
            });
        }
      });
    },
    getExperience() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/hotel/experiences/get/${this.$route.params.slug}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          const {
            _id: experienceID,
            title,
            type,
            openNewPage,
            featuredImage,
            description,
            translations,
            icon,
            hotels,
          } = response.data.experience;

          const experienceData = {
            title: title,
            description: description,
            type: type,
            icon: icon,
            openNewPage: openNewPage,
            featuredImage: featuredImage,
            hotels: hotels.map((hotel) => {
              return { hotel: hotel.hotel };
            }),
            translations: {
              hi: {
                title: translations.hi.title,
                description: translations.hi.description,
              },
              mr: {
                title: translations.mr.title,
                description: translations.mr.description,
              },
            },
          };
          console.log(experienceData)
          this.experienceID = experienceID;
          this.experience = experienceData;
        })
        .catch((error) => {
          console.log(error);
        });
    },
     handleIconSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        this.experience.icon = mediaObject.media.src;
      }
    },
    getHotels() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/get-all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.hotels = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Hotel List");
        });
    },
  },
  mounted() {
    this.getExperience();
    this.getHotels();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>